import { onUnload } from "./events";

/** @type {HTMLAudioElement | undefined} */
let audio;

export default function registerPlayWavHandlers() {
  document.body.addEventListener("click", function (event) {
    const play_wav_element = event.target.closest(".play-wav");
    if (!play_wav_element) {
      return;
    }

    if (audio) {
      audio.pause();
    }

    audio = new Audio(play_wav_element.href);
    audio.play();

    event.preventDefault();
    return false;
  });

  onUnload(function () {
    if (audio) {
      audio.pause();
      audio = undefined;
    }
  });
}
